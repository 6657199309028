<template>
  <div class="mb-4">
    <div class="p-4 pt-0 d-flex justify-content-center">
      <div class="md-layout-item md-size-70 md-small-size-100">
        <p class="md-body-2">
          Please add any additional comments for our evaluations.
        </p>
        <div>
          <textarea
            v-model="doctorForm.postData.person.comments"
            type="textarea"
            placeholder="Add your comments."
            class="w-100 h-100p"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["doctorForm"],
};
</script>
