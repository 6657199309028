<template>
  <md-dialog :md-active.sync="manageDoctor.showConfidentialConfirmation">
    <div class="modal-size-medium">
      <div class="modal-content scroll">
        <div class="md-layout-item" style="text-align: center">
          <img
            width="150"
            height="auto"
            src="@/assets/img/GlobeChek.png"
            alt="Globe Check Logo"
          />
        </div>
        <div class="md-layout-item" style="font-size: 1rem">
          <p>Welcome Doctor!</p>
          <p>
            To register with GlobeChek for referrals you must currently be
            qualified as Ophthalmologist (MD) or equivalent, Optometrist (OD),
            or Osteopath (DO).
          </p>
          <p>
            Please complete each of the following sections. Once you have
            submitted your completed application, you will receive an email with
            your login credentials and/or additional instructions within 48
            hours.
          </p>
          <p>
            If you experience any problems registering, please contact us at
            <a href="mailto:Info@GlobeChek.com">Info@GlobeChek.com</a>
          </p>
          <div class="modal-footer">
            <md-button class="btn-outline" @click="handleCancelClick"
              >CANCEL
            </md-button>
            <md-button
              class="md-primary"
              @click="manageDoctor.showConfidentialConfirmation = false"
              >REGISTER
            </md-button>
          </div>
        </div>
      </div>
    </div>
  </md-dialog>
</template>
<script>
export default {
  inject: ["manageDoctor", "navigationBlocker"],
  methods: {
    handleCancelClick() {
      this.navigationBlocker.val = true;
      this.manageDoctor.showConfidentialConfirmation = false;
      this.$router.back();
    },
  },
};
</script>
