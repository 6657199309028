<template>
  <div class="mb-4">
    <div class="pt-0 d-flex" style="max-height: 350px; overflow: auto">
      <div class="md-layout" style="width: 100%">
        <div
          class="
            md-layout-item
            md-large-size-33
            md-medium-size-50
            md-small-size-100
            md-size-100
          "
          style="margin-bottom: 15px"
          v-for="region in manageDoctor.regionClients"
          :key="region.id"
        >
          <div class="checkbox-list">
            <div class="md-layout-item md-size-100">
              <checkbox-single-input
                :label="region.displayValue"
                v-model="region.isChecked"
              />
            </div>
            <div class="md-layout-item md-size-100">
              <div
                class="checkbox-list"
                style="border: none; padding: 0"
                v-if="region.clients.length > 0"
              >
                <check-box-input
                  label="Clients"
                  :options="
                    region.clients.map((x) => ({
                      value: x.id,
                      text: x.displayValue,
                    }))
                  "
                  @onChange="(client) => handleClientChecked(region, client)"
                  v-model="region.selectedClients"
                />
              </div>
              <div v-else>No Clients found.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CheckBoxInput from "../../../components/controls/CheckBoxInput.vue";
export default {
  inject: ["doctorForm", "master", "manageDoctor", "handleClientChecked"],
  components: {
    CheckBoxInput,
  },
};
</script>
