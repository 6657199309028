<template>
  <ValidationListener
    @getIsValid="(isValid) => (doctorForm.isPersonalDataValid = isValid)"
  >
    <div class="info-block mb-2" v-if="manageDoctor.role.name == 'Doctor'">
      <p class="m-0 md-body-2">
        To register with GlobeChek for referrals you must currently be qualified
        *
      </p>
      <template>
        <ValidationHandler rules="required" v-slot="{ error }">
          <div>
            <check-box-input
              name="qualification"
              label=""
              :options="
                master.qualification.map((x) => ({
                  value: x.id,
                  text: x.displayValue,
                }))
              "
              v-model="doctorForm.postData.qualification"
              :error="error"
            />
          </div>
        </ValidationHandler>
      </template>
    </div>
    <div class="d-flex justify-content-center">
      <div v-if="manageDoctor.role.name == 'Doctor'">
        <profile-image
          defaultSrc="doctor"
          :options="{
            feature: 'User',
            featureId: doctorForm.postData.userID,
            category: 'PROFILE',
          }"
          @getFileUploadRef="(ref) => (doctorForm.fileUploadRef = ref)"
        ></profile-image>
      </div>
      <div v-else>
        <profile-image
          defaultSrc="Reader"
          :options="{
            feature: 'User',
            featureId: doctorForm.postData.userID,
            category: 'PROFILE',
          }"
          @getFileUploadRef="(ref) => (doctorForm.fileUploadRef = ref)"
        ></profile-image>
      </div>
    </div>
    <div class="md-layout">
      <div
        class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
      >
        <div class="d-flex mr-2">
          <label class="labeltext mr-2">Title</label>
          <b>{{ manageDoctor.role.name }}</b>
        </div>
        <ValidationHandler
          :name="doctorForm.postData.person.firstName"
          rules="required|alpha_spaces"
          v-slot="{ error }"
        >
          <TextInput
            label="First Name *"
            placeholder="First Name *"
            icon="perm_identity"
            :required="true"
            v-model="doctorForm.postData.person.firstName"
            :error="error"
            icons="perm_identity"
          />
        </ValidationHandler>
        <ValidationHandler
          :name="doctorForm.postData.person.lastName"
          rules="required|alpha_spaces|min:2"
          v-slot="{ error }"
        >
          <TextInput
            type="text"
            label="Last Name *"
            placeholder="Last Name *"
            icon="perm_identity"
            :required="true"
            v-model="doctorForm.postData.person.lastName"
            :error="error"
            icons="record_voice_over"
          />
        </ValidationHandler>
        <div>
          <ValidationHandler
            :name="doctorForm.postData.person.gender"
            rules="required"
            v-slot="{ error }"
          >
            <label class="labeltext">Gender *</label>
            <div class="mb-3 d-flex" style="margin-bottom: 0">
              <radio-button-input
                name="gender"
                :options="
                  master.genderList.map((x) => ({
                    value: x.value,
                    text: x.displayValue,
                  }))
                "
                v-model="doctorForm.postData.person.gender"
                :error="error"
              />
            </div>
          </ValidationHandler>
        </div>
        <div
          class="check-sm pt-2"
          v-if="String(manageDoctor.userType).toLowerCase() == 'doctor'"
        >
          <label class="labeltext">Would You like to act as a reader?</label>
          <div>
            <checkbox-single-input
              name="isReader"
              label="Yes"
              v-model="doctorForm.postData.isReader"
            />
          </div>
        </div>
      </div>
      <div
        class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
      >
        <label class="labeltext">Contact Details</label>
        <ValidationHandler
          :name="doctorForm.postData.person.email"
          rules="required|email"
          v-slot="{ error }"
        >
          <TextInput
            type="text"
            label="Email *"
            placeholder="Email *"
            icon="mail_outline"
            :required="false"
            v-model="doctorForm.postData.person.email"
            :error="error"
            icons="email"
          />
        </ValidationHandler>
        <div class="d-flex">
          <div class="md-layout-item p-0">
            <ValidationHandler
              :name="doctorForm.postData.person.cellNumber"
              rules="required|min:14|max:18"
              v-slot="{ error }"
            >
              <TextInput
                type="text"
                label="Phone Number *"
                placeholder="Phone Number *"
                icon="settings_cell"
                :required="true"
                v-model="doctorForm.postData.person.cellNumber"
                v-mask="'(###) ###-########'"
                :error="error"
                icons="smartphone"
              />
              <!-- <vue-tel-input
                 defaultCountry="American Samoa"
                 disabledFetchingCountry=false
                 dropdownOptions={ disabledDialCode: false, tabindex: 0 }
                 maxLen=11
                 placeholder="Phone Number *"
                  v-model="doctorForm.postData.person.cellNumber"
                ></vue-tel-input> -->
            </ValidationHandler>
          </div>
          <div class="d-flex" style="padding-left: 10px">
            <checkbox-single-input
              name="allowSMS"
              label="Allow SMS"
              v-model="doctorForm.postData.person.allowSMS"
            />
          </div>
        </div>
        <ValidationHandler
          :name="doctorForm.postData.mailingAddress.address1"
          rules="required"
          v-slot="{ error }"
        >
          <TextInput
            type="text"
            label="Mailing Address *"
            placeholder="Mailing Address *"
            icon="place"
            :required="true"
            v-model="doctorForm.postData.mailingAddress.address1"
            :error="error"
            icons="room"
          />
        </ValidationHandler>
        <div class="d-flex">
          <div class="w-50 mr-1">
            <ValidationHandler
              :name="doctorForm.postData.mailingAddress.city"
              rules="required|alpha_spaces"
              v-slot="{ error }"
            >
              <TextInput
                type="text"
                label="City *"
                placeholder="City *"
                icon="location_city"
                :required="true"
                v-model="doctorForm.postData.mailingAddress.city"
                :error="error"
                icons="domain"
              />
            </ValidationHandler>
          </div>
          <div class="w-50">
            <ValidationHandler
              :name="doctorForm.postData.mailingAddress.state"
              rules="required"
              v-slot="{ error }"
            >
              <div class="text-field">
                <select-input
                  name="state"
                  displayName="State *"
                  :options="
                    master.stateList.map((x) => ({
                      value: x.id,
                      text: x.displayValue,
                    }))
                  "
                  v-model="doctorForm.postData.mailingAddress.state"
                  :required="true"
                  :error="error"
                />
              </div>
            </ValidationHandler>
          </div>
        </div>
        <div class="d-flex">
          <div class="mr-1">
            <ValidationHandler
              :name="doctorForm.postData.mailingAddress.country"
              rules="required"
              v-slot="{ error }"
            >
              <div class="text-field">
                <select-input
                  name="country"
                  displayName="Country *"
                  placeHolder="country"
                  icon="outlined_flag"
                  :options="
                    master.countryCodeList.map((x) => ({
                      value: x.id,
                      text: x.displayValue,
                    }))
                  "
                  v-model="doctorForm.postData.mailingAddress.country"
                  :required="true"
                  :error="error"
                />
              </div>
            </ValidationHandler>
          </div>

          <ValidationHandler
            :name="doctorForm.postData.mailingAddress.zipCode"
            rules="required|min:5|max:16"
            v-slot="{ error }"
          >
            <TextInput
              type="text"
              label="Zip Code *"
              placeholder="Zip Code *"
              :required="true"
              v-model="doctorForm.postData.mailingAddress.zipCode"
              :error="error"
              v-mask="'#####-#####-####'"
            />
          </ValidationHandler>
        </div>
      </div>
    </div>
  </ValidationListener>
</template>
<script>
import TextInput from "../../../components/controls/TextInput.vue";
// import SelectInput from "../../../components/controls/SelectInput.vue";
import RadioButtonInput from "../../../components/controls/RadioButtonInput.vue";
import Feature from "../../../constants/Features";
import ProfileImage from "../../../components/Globechek/Register/ProfileImage.vue";

export default {
  inject: [
    "doctorForm",
    "master",
    "manageDoctor",
    "navigationBlocker",
    "popupInvoked",
  ],
  data() {
    return {
      Feature,
      doctor: {
        qualification: 0,
        firstName: 0,
        lastName: 0,
        gender: 0,
        isReader: 0,
        email: 0,
        cellNumber: 0,
        address1: 0,
        city: 0,
        state: 0,
        country: 0,
        zipCode: 0,
      },
    };
  },
  components: {
    TextInput,
    //SelectInput,
    RadioButtonInput,
    ProfileImage,
  },
  watch: {
    "doctorForm.postData.qualification"() {
      this.doctor.qualification = this.doctor.qualification + 1;
      if (this.$route.path.includes("edit") && this.doctor.qualification > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.person.firstName"() {
      this.doctor.firstName = this.doctor.firstName + 1;
      if (this.$route.path.includes("edit") && this.doctor.firstName > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.person.lastName"() {
      this.doctor.lastName = this.doctor.lastName + 1;
      if (this.$route.path.includes("edit") && this.doctor.lastName > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.person.gender"() {
      this.doctor.gender = this.doctor.gender + 1;
      if (this.$route.path.includes("edit") && this.doctor.gender > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.isReader"() {
      this.doctor.isReader = this.doctor.isReader + 1;
      if (this.$route.path.includes("edit") && this.doctor.isReader > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.person.email"() {
      this.doctor.email = this.doctor.email + 1;
      if (this.$route.path.includes("edit") && this.doctor.email > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.person.cellNumber"() {
      this.doctor.cellNumber = this.doctor.cellNumber + 1;
      if (this.$route.path.includes("edit") && this.doctor.cellNumber > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.mailingAddress.address1"() {
      this.doctor.address1 = this.doctor.address1 + 1;
      if (this.$route.path.includes("edit") && this.doctor.address1 > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.mailingAddress.city"() {
      this.doctor.city = this.doctor.city + 1;
      if (this.$route.path.includes("edit") && this.doctor.city > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.mailingAddress.state"() {
      this.doctor.state = this.doctor.state + 1;
      if (this.$route.path.includes("edit") && this.doctor.state > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.mailingAddress.country"() {
      this.doctor.country = this.doctor.country + 1;
      if (this.$route.path.includes("edit") && this.doctor.country > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.mailingAddress.zipCode"() {
      this.doctor.zipCode = this.doctor.zipCode + 1;
      if (this.$route.path.includes("edit") && this.doctor.zipCode > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
  },
  methods: {
    getImageFromFiles(uploadFiles, uploadedFiles) {
      if (uploadFiles && uploadFiles.length > 0) return uploadFiles[0].link;
      else if (uploadedFiles && uploadedFiles.length > 0)
        return uploadedFiles[0].link;
    },
  },
};
</script>
