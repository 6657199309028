<template>
  <div class="mb-4">
    <ValidationListener
      @getIsValid="(isValid) => (doctorForm.isBusinessDataValid = isValid)"
    >
      <div class="md-layout">
        <div class="md-layout-item">
          <ValidationHandler rules="required|alpha_spaces" v-slot="{ error }">
            <TextInput
              type="text"
              label="Primary Business Name *"
              placeholder="Primary Business Name *"
              :required="true"
              icon="badge"
              v-model="doctorForm.postData.practice"
              :error="error"
            />
          </ValidationHandler>
          <TextInput
            type="text"
            label="Business address"
            placeholder="Business address"
            icon="place"
            :required="true"
            v-model="doctorForm.postData.businessAddress.address1"
            tooltip="This is where you see the referral patient"
          />
          <div class="d-flex">
            <div class="mr-1 w-50">
              <ValidationHandler
                rules="required|alpha_spaces"
                v-slot="{ error }"
              >
                <TextInput
                  type="text"
                  label="City *"
                  placeholder="City *"
                  icon="location_city"
                  :required="true"
                  v-model="doctorForm.postData.businessAddress.city"
                  :error="error"
                />
              </ValidationHandler>
            </div>

            <div class="w-50">
              <div class="text-field">
                <select-input
                  name="state"
                  displayName="State"
                  placeHolder="State"
                  :options="
                    master.stateList.map((x) => ({
                      value: x.id,
                      text: x.displayValue,
                    }))
                  "
                  v-model="doctorForm.postData.businessAddress.state"
                  :required="false"
                />
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="mr-1 w-50">
              <ValidationHandler rules="required" v-slot="{ error }">
                <div class="text-field">
                  <select-input
                    name="country"
                    displayName="Country *"
                    placeHolder="Country *"
                    icon="outlined_flag"
                    :options="
                      master.countryCodeList.map((x) => ({
                        value: x.id,
                        text: x.displayValue,
                      }))
                    "
                    v-model="doctorForm.postData.businessAddress.country"
                    :required="true"
                    :error="error"
                  />
                </div>
              </ValidationHandler>
            </div>
            <div class="w-50">
              <ValidationHandler
                rules="required|min:5|max:16"
                v-slot="{ error }"
              >
                <TextInput
                  type="text"
                  label="Zip Code *"
                  placeholder="Zip Code *"
                  :required="true"
                  v-model="doctorForm.postData.businessAddress.zipCode"
                  :error="error"
                  tooltip="This is where you see the referral patient"
                  v-mask="'#####-#####-####'"
                />
              </ValidationHandler>
            </div>
          </div>
          <ValidationHandler rules="required|min:14|max:18" v-slot="{ error }">
            <TextInput
              type="text"
              label="Primary Business Phone *"
              placeholder="Primary Business Phone *"
              icon="local_phone"
              :required="true"
              v-model="doctorForm.postData.businessPhoneNo"
              v-mask="'(###) ###-########'"
              :error="error"
            />
          </ValidationHandler>
          <TextInput
            type="text"
            label="Website URL"
            placeholder="Website URL"
            icon="language"
            :required="true"
            v-model="doctorForm.postData.webSite"
          />
        </div>
        <div class="md-layout-item">
          <label class="labeltext">Additional Information</label>
          <div class="d-flex">
            <div class="w-50 mr-2">
              <ValidationHandler
                :name="doctorForm.postData.person.languages"
                rules="required"
                v-slot="{ error }"
              >
                <div class="checkbox-list">
                  <check-box-input
                    name="languages"
                    label="Language Spoken *"
                    :options="
                      master.languages.map((x) => ({
                        value: x.id,
                        text: x.displayValue,
                      }))
                    "
                    v-model="doctorForm.postData.person.languages"
                    :error="error"
                  />

                  <div class="d-flex">
                    <div
                      v-if="
                        doctorForm.postData.person.languages &&
                        doctorForm.postData.person.languages.includes(345)
                      "
                    >
                      <ValidationHandler
                        :name="doctorForm.postData.person.otherLanguages"
                        rules="required"
                        v-slot="{ error }"
                      >
                        <TextInput
                          type="text"
                          placeholder="Add Languages"
                          v-model="doctorForm.postData.person.otherLanguages"
                          :error="error"
                        />
                        <label>Separate language with a comma.</label>
                      </ValidationHandler>
                    </div>
                  </div>
                </div>
              </ValidationHandler>
            </div>

            <div class="w-50">
              <!-- <span class="md-caption">Insurance Accepted</span> -->
              <div class="checkbox-list">
                <check-box-input
                  name="insurance"
                  label="Insurance Accepted"
                  :options="
                    master.insurance.map((x) => ({
                      value: x.id,
                      text: x.displayValue,
                    }))
                  "
                  v-model="doctorForm.postData.insurance"
                />
                <div class="d-flex">
                  <div
                    v-if="
                      doctorForm.postData.insurance &&
                      doctorForm.postData.insurance.includes(600)
                    "
                  >
                    <ValidationHandler
                      :name="doctorForm.postData.otherInsurance"
                      rules="required"
                      v-slot="{ error }"
                    >
                      <TextInput
                        type="text"
                        placeholder="Add Insurance"
                        :required="true"
                        v-model="doctorForm.postData.otherInsurance"
                        :error="error"
                      />
                    </ValidationHandler>
                    <label>Separate insurance with a comma.</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationListener>
  </div>
</template>
<script>
import TextInput from "../../../components/controls/TextInput.vue";
import SelectInput from "../../../components/controls/SelectInput.vue";
import CheckBoxInput from "../../../components/controls/CheckBoxInput.vue";
export default {
  inject: ["doctorForm", "master", "navigationBlocker"],
  components: {
    TextInput,
    SelectInput,
    CheckBoxInput,
  },
  data() {
    return {
      business: {
        practice: 0,
        address1: 0,
        city: 0,
        country: 0,
        zipCode: 0,
        state: 0,
        businessPhoneNo: 0,
      },
    };
  },
  created() {
    this.navigationBlocker.val = true;
  },
  watch: {
    "doctorForm.postData.practice"() {
      this.business.practice = this.business.practice + 1;
      if (this.$route.path.includes("edit") && this.business.practice > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.businessAddress.address1"() {
      this.business.address1 = this.business.address1 + 1;
      if (this.$route.path.includes("edit") && this.business.address1 > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.businessAddress.city"() {
      this.business.city = this.business.city + 1;
      if (this.$route.path.includes("edit") && this.business.city > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.businessAddress.country"() {
      this.business.country = this.business.country + 1;
      if (this.$route.path.includes("edit") && this.business.country > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.businessAddress.zipCode"() {
      this.business.zipCode = this.business.zipCode + 1;
      if (this.$route.path.includes("edit") && this.business.zipCode > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.businessAddress.state"() {
      this.business.state = this.business.state + 1;
      if (this.$route.path.includes("edit") && this.business.state > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.businessPhoneNo"() {
      this.business.businessPhoneNo = this.business.businessPhoneNo + 1;
      if (this.business.businessPhoneNo > 1) this.navigationBlocker.val = false;
    },
  },
};
</script>
