<template>
  <div class="mb-4">
    <ValidationListener
      @getIsValid="(isValid) => (doctorForm.isQualificationDataValid = isValid)"
    >
      <div class="pt-0 d-flex justify-content-center">
        <div class="md-layout-item md-size-60">
          <div v-if="manageDoctor.role.name == 'Doctor'">
            <div class="text-field">
              <select-input
                name="specialization"
                displayName="Eye Care Provider"
                placeHolder="Eye Care Provider"
                :options="
                  master.specializations.map((x) => ({
                    value: x.id,
                    text: x.displayValue,
                  }))
                "
                v-model="doctorForm.postData.specialization"
                :required="true"
              />
            </div>
            <div
              v-if="
                master.qualification &&
                master.qualification.find(
                  (x) => x.id == doctorForm.postData.qualification
                ) &&
                master.qualification.find(
                  (x) => x.id == doctorForm.postData.qualification
                ).name != 'OphOD'
              "
            >
              <div class="text-field">
                <select-input
                  name="subspecialization"
                  displayName="Eye Care Specality"
                  placeHolder="Eye Care Specality"
                  :options="
                    master.subspecializations.map((x) => ({
                      value: x.id,
                      text: x.displayValue,
                    }))
                  "
                  v-model="doctorForm.postData.subspecialization"
                  :required="true"
                />
              </div>
              <div class="text-field">
                <select-input
                  name="speciality"
                  displayName="Non Eye Care Provider"
                  placeHolder="Non Eye Care Provider"
                  :options="
                    master.specialities.map((x) => ({
                      value: x.id,
                      text: x.displayValue,
                    }))
                  "
                  v-model="doctorForm.postData.speciality"
                  :required="true"
                />
              </div>
            </div>
          </div>
          <div>
            <label class="labeltext">Are you board certified?</label>
            <div class="mb-3 d-flex">
              <radio-button-input
                rules="required"
                :options="
                  boardCerifications.map((x) => ({
                    value: x.value,
                    text: x.displayValue,
                  }))
                "
                v-model="doctorForm.postData.boardCertificate"
              />
            </div>
          </div>
          <div>
            <label class="labeltext">What was your certification date? </label>
            <date-dropdown
              ref="certificationDateRef"
              :default="doctorForm.postData.certificationDate"
              min="1951"
              max="2999"
              v-model="doctorForm.postData.certificationDate"
              style="width: 70%; margin-bottom: 5px"
            />
          </div>

          <div>
            <label class="labeltext">Additional Information</label>
            <ValidationHandler
              :name="doctorForm.postData.caqh"
              rules="alpha_num|length:8"
              v-slot="{ error }"
            >
              <TextInput
                type="text"
                label="CAQH Number"
                icon="health_and_safety"
                placeholder="CAQH Number"
                v-model="doctorForm.postData.caqh"
                :error="error"
                style="color: black"
              />
            </ValidationHandler>
            <ValidationHandler
              :name="doctorForm.postData.npi"
              rules="numeric|length:10"
              v-slot="{ error }"
            >
              <TextInput
                type="text"
                label="NPI Number"
                icon="health_and_safety"
                placeholder="NPI Number"
                v-model="doctorForm.postData.npi"
                :error="error"
              />
            </ValidationHandler>
            <div v-if="manageDoctor.role.name == 'Doctor'">
              <ValidationHandler
                :name="doctorForm.postData.license"
                rules="alpha_num"
                v-slot="{ error }"
              >
                <TextInput
                  type="text"
                  label="License Number"
                  icon="health_and_safety"
                  placeholder="License Number"
                  v-model="doctorForm.postData.license"
                  :error="error"
                />
              </ValidationHandler>
            </div>
          </div>
        </div>
      </div>
    </ValidationListener>
  </div>
</template>
<script>
import TextInput from "../../../components/controls/TextInput.vue";
import SelectInput from "../../../components/controls/SelectInput.vue";
import RadioButtonInput from "../../../components/controls/RadioButtonInput.vue";
import DateDropdown from "vue-date-dropdown";
export default {
  components: {
    DateDropdown,
    TextInput,
    SelectInput,
    RadioButtonInput,
  },
  created() {
    this.navigationBlocker.val = true;
  },
  watch: {
    "doctorForm.postData.certificationDate": function () {
      if (this.doctorForm.canSetDOB && this.doctorForm.dobObj) {
        this.$refs.certificationDateRef._data.selectedDay =
          this.doctorForm.dobObj.day;
        this.$refs.certificationDateRef._data.selectedMonth =
          this.doctorForm.dobObj.month;
        this.$refs.certificationDateRef._data.selectedYear =
          this.doctorForm.dobObj.year;
        this.doctorForm.canSetDOB = false;
        this.qualification.certificationDate =
          this.qualification.certificationDate + 1;
        if (
          this.$route.path.includes("edit") &&
          this.qualification.certificationDate > 1
        )
          this.navigationBlocker.val = false;
        else if (
          this.$route.path.includes("add") |
          this.$route.path.includes("/register/doctor")
        ) {
          this.navigationBlocker.val = false;
        }
      }
    },
    "doctorForm.postData.license"() {
      this.qualification.license = this.qualification.license + 1;
      if (this.$route.path.includes("edit") && this.qualification.license > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.npi"() {
      this.qualification.npi = this.qualification.npi + 1;
      if (this.$route.path.includes("edit") && this.qualification.npi > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.caqh"() {
      this.qualification.caqh = this.qualification.caqh + 1;
      if (this.$route.path.includes("edit") && this.qualification.caqh > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.boardCertificate"() {
      this.qualification.boardCertificate =
        this.qualification.boardCertificate + 1;
      if (
        this.$route.path.includes("edit") &&
        this.qualification.boardCertificate > 1
      )
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.speciality"() {
      this.qualification.speciality = this.qualification.speciality + 1;
      if (
        this.$route.path.includes("edit") &&
        this.qualification.speciality > 1
      )
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.specialization"() {
      this.qualification.specialization = this.qualification.specialization + 1;
      if (
        this.$route.path.includes("edit") &&
        this.qualification.specialization > 1
      )
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "doctorForm.postData.subspecialization"() {
      this.qualification.subspecialization =
        this.qualification.subspecialization + 1;
      if (
        this.$route.path.includes("edit") &&
        this.qualification.subspecialization > 1
      )
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("/register/doctor")
      ) {
        this.navigationBlocker.val = false;
      }
    },
  },
  inject: [
    "doctorForm",
    "master",
    "boardCerifications",
    "manageDoctor",
    "navigationBlocker",
  ],
  data() {
    return {
      qualification: {
        certificationDate: 0,
        subspecialization: 0,
        specialization: 0,
        speciality: 0,
        boardCertificate: 0,
        caqh: 0,
        npi: 0,
        license: 0,
      },
    };
  },
};
</script>
x
