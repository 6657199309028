<template>
  <div>
    <loading :active="progress.isDetailsLoading" :can-cancel="true"></loading>
    <md-steppers :md-active-step.sync="active" md-linear>
      <md-step
        id="first"
        md-label="Personal Details"
        :md-error="firstStepErr"
        :md-done.sync="first"
      >
        <first-step></first-step>
        <div class="text-center">
          <md-button class="btn-outline" @click="$router.back()"
            >Exit</md-button
          >
          <md-button
            class="md-raised md-primary"
            @click.native="nextStep('first', 'second')"
            >Next</md-button
          >
        </div>
      </md-step>

      <md-step
        id="second"
        md-label="Business Details"
        :md-error="secondStepErr"
        :md-done.sync="second"
      >
        <second-step></second-step>
        <div class="text-center">
          <md-button
            class="btn-outline"
            @click.native="previosStep('first', 'first')"
            >Previous</md-button
          >
          <md-button
            class="md-raised md-primary"
            @click="nextStep('second', 'third')"
            >Next</md-button
          >
        </div>
      </md-step>

      <md-step
        id="third"
        md-label="Qualification Details"
        :md-error="thirdStepErr"
        :md-done.sync="third"
      >
        <third-step></third-step>
        <div class="text-center">
          <md-button
            class="btn-outline"
            @click.native="previosStep('second', 'second')"
            >Previous</md-button
          >
          <md-button
            class="md-raised md-primary"
            @click="nextStep('third', 'fourth')"
            >Next</md-button
          >
        </div>
      </md-step>
      <md-step id="fourth" md-label="Comments" :md-done.sync="fourth">
        <fourth-step></fourth-step>
        <div class="text-center">
          <spinner
            :diameter="25"
            style="padding: 10px"
            v-if="progress.isSaving"
          ></spinner>
          <div v-else>
            <md-button
              class="btn-outline"
              @click="previosStep('third', 'third')"
              >Previous</md-button
            >
            <md-button
              class="md-raised md-primary"
              @click="nextStep('fourth', 'fifth')"
              v-if="
                checkPermission(Permissions.SuperAdmin) &&
                (manageDoctor.id || manageDoctor.page != 'Register')
              "
              >Next</md-button
            >
            <fragment v-else-if="manageDoctor.page == 'Approval'">
              <md-button
                class="md-raised md-primary"
                @click="handleDoctorApprove(true)"
                >Approve</md-button
              >
              <md-button
                class="md-raised md-primary"
                @click="handleDoctorApprove(false)"
                >Reject</md-button
              >
            </fragment>
            <md-button
              class="md-raised md-primary"
              @click="handleDoctorSave()"
              v-else
              >{{
                manageDoctor.page == "Register" ? "Finish" : "Save"
              }}</md-button
            >
          </div>
        </div>
      </md-step>
      <has-permission :permission="Permissions.SuperAdmin">
        <md-step
          id="fifth"
          md-label="Admin Entry"
          :md-done.sync="fourth"
          v-if="manageDoctor.id || manageDoctor.page != 'Register'"
        >
          <fifth-step></fifth-step>
          <div class="text-center">
            <spinner
              :diameter="25"
              style="padding: 10px"
              v-if="progress.isSaving"
            ></spinner>
            <div v-else>
              <md-button
                class="btn-outline"
                @click="previosStep('fourth', 'fourth')"
                >Previous</md-button
              >
              <fragment v-if="manageDoctor.page == 'Approval'">
                <md-button
                  class="md-raised md-primary"
                  @click="handleDoctorApprove(true)"
                  >Approve</md-button
                >
                <md-button
                  class="md-raised md-primary"
                  @click="handleDoctorApprove(false)"
                  >Reject</md-button
                >
              </fragment>
              <md-button
                v-else
                class="md-raised md-primary"
                @click="handleDoctorSave()"
                >Save</md-button
              >
            </div>
          </div>
        </md-step>
      </has-permission>
    </md-steppers>
    <confidential-confirmation></confidential-confirmation>
  </div>
</template>
<script>
import FirstStep from "./1_PersonalDetails.vue";
import SecondStep from "./2_BusinessDetails.vue";
import ThirdStep from "./3_QualificationDetails.vue";
import FourthStep from "./4_CommentsDetails.vue";
import FifthStep from "./5_AdminEntry.vue";
import ConfidentialConfirmation from "./ConfidentialConfirmation.vue";

export default {
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    FifthStep,
    ConfidentialConfirmation,
  },
  inject: [
    "progress",
    "manageDoctor",
    "checkFormValid",
    "handleDoctorSave",
    "handleDoctorApprove",
    "Permissions",
    "checkPermission",
  ],
  data() {
    return {
      firstStepErr: null,
      secondStepErr: null,
      thirdStepErr: null,
      active: "first",
      first: false,
      second: false,
      third: false,
      fourth: false,
    };
  },
  methods: {
    nextStep(current, next) {
      if (["first", "second", "third"].includes(current))
        this.checkFormValid(current).then((isValid) => {
          if (isValid) this.moveToNextStep(current, next);
          else this[current + "StepErr"] = "There is an error!";
        });
      else this.moveToNextStep(current, next);
    },
    moveToNextStep(current, next) {
      this[current] = true;
      this[current + "StepErr"] = null;
      if (next) {
        this.active = next;
      }
    },
    previosStep(id, index) {
      this[id] = true;
      if (index) {
        this.active = index;
      }
    },
  },
};
</script>
